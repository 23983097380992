import React, { useEffect, useContext, useState } from "react";
import logo from "../assets/images/logo-fullsize-colored.png";
import { Box, Button } from "@mui/material";
import UserSearch from "../components/UserSearch";
import MaterialSearch from "../components/MaterialSearch";
import AdminSearch from "../components/AdminSearch";
import { useNavigate } from "react-router-dom";
import { TabList, TabPanel } from "@mui/lab";
import Badge from "@mui/material/Badge";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import RegisteredUsers from "../components/RegisteredUsers";
import MaterialsList from "../components/MaterialsList";
import PageContents from "../components/PageContents";
import Menu from "@mui/material/Menu";
import AdminUsers from "../components/AdminUsers";
import axios from "axios";
import { AccountContext } from "../context/Account";
import algoliasearch from "algoliasearch/lite";

const DEFAULT_TAB = "registered-users";

const searchClient = algoliasearch(
  "9L6NOAXQFO",
  "03ab2350f8d94d1a02d9635915953df8"
);
const searchIndex = searchClient.initIndex("dev_materials");

function Header() {
  const { logout } = useContext(AccountContext);
  const [searchTerm, setSearchTerm] = useState();
  // const [searchTermAdmin, setSearchTermAdmin] = useState("");
  const [materials, setMaterials] = useState(null);
  const [registeredUsers, setRegisteredUsers] = useState(null);
  const [registeredUsersId, setRegisteredUsersId] = useState(null);
  const [registeredAdminUsers, setRegisteredAdminUsers] = useState(null);
  const [count, setCount] = useState(0);
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB);
  const [filteredAdminUsers, setFilteredAdminUsers] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [filteredMaterials, setFilteredMaterials] = useState(null);
  const [query, setQuery] = useState(null);

  let navigate = useNavigate();

  const { userProfile } = useContext(AccountContext);

  useEffect(() => {
    getTotalPendingMaterials();
  }, []);

  const getTotalPendingMaterials = () => {
    searchIndex
      .search("", {
        filters: "status:pending",
      })
      .then(({ hits }) => {
        setCount(hits.length);
        // console.log("pending", hits);
      });
  };

  const handleChange = (event, tabName) => {
    if (tabName === "materials") {
      getTotalPendingMaterials();
    }

    if (tabName === "registered-users") {
      // getRegisteredUsers();
    }

    setActiveTab(tabName);
    navigate(`/${tabName}`);
  };

  useEffect(() => {
    console.log("searchValues", searchTerm);
    const query = searchTerm ? searchTerm : "";

    searchIndex.search(query).then(({ hits }) => {
      // setMaterials([]);
      console.log("hits", hits);
      setMaterials(hits);
    });
  }, [searchTerm]);

  // on init
  const getRegisteredUsers = async () => {
    const baseUrl =
      "https://dxxzla1v6k.execute-api.us-east-1.amazonaws.com/dev";
    try {
      const response = await axios.get(`${baseUrl}/user-list`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setRegisteredUsers(response.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getRegisteredUsers();
  }, []);

  // on user search submit
  const usersQuery = async (e) => {
    if (query.length === 0) {
      getRegisteredUsers();
    }
    // console.log(query, "test2");
    if (query?.length > 2) {
      const baseUrl =
        "https://dxxzla1v6k.execute-api.us-east-1.amazonaws.com/dev/";
      try {
        const response = await axios.get(`${baseUrl}search?q=${query}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setRegisteredUsers(response.data.hits.hit);
        console.log(response.data.hits.hit, "query");
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const searchAdmin = (searchQuery) => {
    if (searchQuery.length === 0) {
      setFilteredAdminUsers(registeredAdminUsers);
    } else if (searchQuery.length < 3) {
      return;
    }

    const a = registeredAdminUsers.filter((x) => {
      const concatValues = `${x.Attributes[0].Value} ${x.Attributes[1].Value}`;
      return concatValues.toLowerCase().includes(searchQuery.toLowerCase());
    });
    console.log(a);
    setFilteredAdminUsers(a);
  };

  const searchUsers = (searchQuery) => {
    if (searchQuery.length === 0) {
      setFilteredUsers(query);
    } else if (searchQuery.length < 3) {
      return;
    }

    const a = query.filter((x) => {
      const concatValues = `${x.data.hits.hit.fields.firstname} ${x.data.hits.hit.fields.lastname}`;
      return concatValues.toLowerCase().includes(searchQuery.toLowerCase());
    });
    console.log(a);
    setFilteredUsers(a);
  };

  const searchMaterials = (searchQuery) => {
    if (searchQuery.length === 0) {
      setFilteredMaterials(materials);
    } else if (searchQuery.length < 3) {
      return;
    }

    const a = materials.filter((x) => {
      const concatValues = `${x.ownerName} ${x.description}`;
      return concatValues.toLowerCase().includes(searchQuery.toLowerCase());
    });
    console.log(a);
    setFilteredMaterials(a);
  };

  const getRegisteredAdminUsers = async () => {
    const baseUrl =
      "https://dxxzla1v6k.execute-api.us-east-1.amazonaws.com/dev";
    try {
      const response = await axios.get(`${baseUrl}/list-admin-users/`);
      const responseRoles = await axios.get(`${baseUrl}/admin-user`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response) {
        let adminUsers = [];
        response.data.forEach((admin) => {
          responseRoles.data.forEach((role) => {
            if (admin.Username === role.adminId) {
              admin.role = role.role;
              admin.adminId = role.adminId;
              adminUsers.push(admin);
            }
          });
        });

        setRegisteredAdminUsers(adminUsers);
        console.log(adminUsers);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getRegisteredAdminUsers();
  }, []);

  useEffect(() => {
    if (registeredAdminUsers) {
      console.log(registeredAdminUsers, "hello");
    }
  }, [registeredAdminUsers]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <TabContext value={activeTab}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <img className="logo" src={logo} alt="logo" />
            <Box sx={headerContainer}>
              <TabList
                className="tab-list"
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab
                  sx={{
                    fontFamily: "InterMedium",
                    fontWeight: "800",
                  }}
                  label="Users"
                  onClick={handleClick}
                />
              </TabList>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <TabList
                  sx={{
                    alignSelf: "center",
                    "& .MuiTabs-flexContainer": {
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    },
                  }}
                  className="tab-list"
                  value={activeTab}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="none"
                  aria
                >
                  <Tab
                    sx={{
                      fontFamily: "InterMedium",

                      fontWeight: "800",
                    }}
                    value="registered-users"
                    label="Registered Users"
                    onClick={handleClose}
                  />
                  <Tab
                    sx={{
                      fontFamily: "InterMedium",
                      fontFamily: "InterMedium",

                      fontWeight: "800",
                    }}
                    value="admin-users"
                    label="Admin Users"
                    onClick={handleClose}
                  />
                </TabList>
              </Menu>
              <TabList
                sx={{
                  alignSelf: "center",
                  "& .MuiTabs-flexContainer": {
                    justifyContent: "center",
                  },
                }}
                className="tab-list"
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab
                  sx={{
                    fontFamily: "InterMedium",

                    fontWeight: "800",
                  }}
                  value="materials"
                  label="Materials"
                />
                {count > 0 ? (
                  <Badge
                    sx={{ marginTop: "10px" }}
                    badgeContent={count}
                    color="error"
                  />
                ) : (
                  ""
                )}
                <Tab
                  sx={{
                    fontFamily: "InterMedium",
                    fontWeight: "800",
                  }}
                  value="contents"
                  label="Contents"
                />
              </TabList>
            </Box>
            <Button variant="outlined" onClick={() => logout()}>
              Logout
            </Button>
          </Box>
        </Box>
        <TabPanel sx={{ padding: "0px" }} value="admin-users">
          {registeredAdminUsers && registeredAdminUsers.length !== 0 && (
            <>
              <h2>Admin Users</h2>
              <AdminSearch
                search={(searchQuery) => searchAdmin(searchQuery)}
                searchSubmit={() => getRegisteredAdminUsers()}
              />
              <AdminUsers
                setRegisteredAdminUsers={setRegisteredAdminUsers}
                registeredAdminUsers={registeredAdminUsers}
                filteredAdminUsers={filteredAdminUsers}
              />
            </>
          )}
        </TabPanel>
        <TabPanel sx={{ padding: "0px" }} value="registered-users">
          {/* {registeredUsers && ( */}
          <>
            <h2>Registered Users</h2>
            <UserSearch
              // search={(searchQuery) => searchUsers(searchQuery)}
              // searchSubmit={() => getRegisteredUsers()}
              usersQuery={usersQuery}
              setQuery={(searchQuery) => setQuery(searchQuery)}
            />
            <RegisteredUsers
              // setRegisteredUsers={setRegisteredUsers}
              registeredUsers={registeredUsers}
              // filteredUsers={filteredUsers}
            />
          </>
          {/* )} */}
        </TabPanel>
        <TabPanel sx={{ padding: "0px" }} value="materials">
          {materials && (
            <>
              <h2>Materials</h2>
              <MaterialSearch
                // setSearchTerm={(value) => setSearchTerm(value)}
                // search={(searchQuery) => searchMaterials(searchQuery)}
                search={(searchQuery) => setSearchTerm(searchQuery)}
                // searchSubmit={() => getMaterials()}
              />
              <MaterialsList
                setCount={() => setCount((previousValue) => previousValue - 1)}
                // setMaterials={setMaterials}
                materials={materials}
                registeredUsers={registeredUsers}
                // getMaterials={() => getMaterials()}
                filteredMaterials={filteredMaterials}
              />
            </>
          )}
        </TabPanel>
        <TabPanel sx={{ padding: "0px" }} value="contents">
          <h2>Contents</h2>
          <PageContents />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

const headerContainer = {
  display: "flex",
  justifyContent: "center",
};

export default Header;
