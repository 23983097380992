const ErrorMessage = ({message}) => {
    return <div style={style}>{message}</div>
  }
  
  const style = {
    paddingTop:'5px',
    color:"#ed4337",
    fontFamily:'Arial, monoscope',
    fontSize: '0.75rem'
  }
  
  export default ErrorMessage
  