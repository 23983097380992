import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { FormControl, Grid, Icon } from "@mui/material";
import { COLORS } from "../constants/config";
import InputAdornment from "@mui/material/InputAdornment";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import OutlinedInput from "@mui/material/OutlinedInput";
import { NavLink } from "react-router-dom";

const AdminSearch = ({ search, searchSubmit }) => {
  return (
    <FormControl
      sx={{ m: 1, display: "flex", flexDirection: "row", margin: "0" }}
      variant="standard"
    >
      <OutlinedInput
        placeholder="Search admins"
        id="admin-user-search"
        type="text"
        variant="outlined"
        sx={inputStyle}
        onChange={(e) => search(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              // onClick={(e) => search(e.target.value)}
              aria-label="search admin users"
              // onClick={handleClickShowPassword}
              // onMouseDown={handleMouseDownPassword}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      <NavLink to="/registration">
        <IconButton
          disableRipple
          sx={{ backgroundColor: "#fff", padding: "0 10px" }}
        >
          <PersonAddIcon />
          <p>Add Users</p>
        </IconButton>
      </NavLink>
    </FormControl>
  );
};

const inputStyle = {
  margin: 0,
  // input label when focused
  "& label.Mui-focused": {
    color: COLORS.primary,
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.primary,
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: COLORS.primary,
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: COLORS.primary,
    },
  },

  "& input:focus::placeholder": {
    color: "transparent",
  },
};

export default AdminSearch;
