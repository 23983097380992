import Header from "../layout/Header";
import { Box } from "@mui/material";

function Admin() {
  return (
    <Box className={"custom-container"}>
      <Header />
    </Box>
  );
}

export default Admin;
