import { useState, useEffect } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";

const UpdateUserRole = ({ registeredAdminUser, updateRole }) => {
  const [selected, setSelected] = useState();

  return (
    <FormControl>
      <FormLabel sx={formLabelStyle} id="demo-row-radio-buttons-group-label">
        Access Level
      </FormLabel>
      <RadioGroup
        onChange={(e) => setSelected(e.target.value)}
        defaultValue={registeredAdminUser.role}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          sx={formControlStyle}
          value="1"
          control={<Radio sx={radioStyle} />}
          label="Admin"
        />
        <FormControlLabel
          sx={formControlStyle}
          value="2"
          control={<Radio sx={radioStyle} />}
          label="Editor"
        />
        <FormLabel sx={formLabelStyle} id="demo-row-radio-buttons-group-label">
          <Button variant="outlined" onClick={() => updateRole(selected)}>
            Save
          </Button>
          <Button variant="outlined">Cancel</Button>
        </FormLabel>
      </RadioGroup>
    </FormControl>
  );
};

const radioStyle = {
  color: "#76767680",
  "&.Mui-checked": {
    color: "#6823D1",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
};

const formControlStyle = {
  paddingTop: "10px",
  color: "#000 !important",
  fontFamily: "InterMedium",
  "&.MuiFormControlLabel-root .MuiFormControlLabel-label": { fontSize: "13px" },
};

const formLabelStyle = {
  paddingTop: "10px",
  color: "#000 !important",
  fontFamily: "InterMedium",
  fontWeight: "600",
  fontSize: "13px",
};

export default UpdateUserRole;
