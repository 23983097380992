import { Outlet, Navigate, NavLink } from "react-router-dom";
import useLocalStorage from "../utils/hooks/useLocalStorage";

const ProtectedRoutes = () => {
  const [loggedIn] = useLocalStorage("loggedIn");
  const useAuth = () => {
    return loggedIn;
  };

  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
