import Modal from "@mui/material/Modal";
import Box from "@mui/material/Button";
import spinnerSvg from "../assets/images/spinner.svg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { COLORS, BUTTONSTYLE } from "../constants/config";
import { useEffect } from "react";

function LoaderModal({ closeForm, loadingType, hasText }) {
  // modals
  const [loading, setLoading] = useState(0);

  useEffect(() => {
    setLoading(loadingType);
  }, [loadingType]);

  const loadingModal = () => {
    setTimeout(() => {
      setLoading(2);
    }, 2000);

    setTimeout(() => {
      setLoading(0);
      closeForm();
    }, 7000);
  };

  return (
    <div>
      <Modal
        open={loading !== 0}
        // open={true}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component={"div"}
          sx={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="span"
            sx={{
              background: "#fff",
              display: "flex",
              flexDirection: "column",
              minHeight: "250px",
              minWidth: "250px",
              height: "auto",
              justifyContent: "space-evenly",
              "&:hover": {
                background: "#fff",
              },
            }}
          >
            {loading === 1 && (
              <>
                <img
                  style={{ width: "150px", height: "150px" }}
                  src={spinnerSvg}
                  alt="submitting"
                />
                {hasText && (
                  <Typography
                    variant="h5"
                    sx={{ color: "#6823D1", fontFamily: "InterBold" }}
                  >
                    Submitting...
                  </Typography>
                )}
                {/* <img src={creatingSvg} alt='creating post' /> */}
              </>
            )}
            {loading === 2 && (
              <>
                <CheckCircleOutlineIcon
                  sx={{ color: "#6823D1", fontSize: "5em" }}
                />
                <Typography
                  variant="h5"
                  sx={{ color: "#6823D1", fontFamily: "InterBold" }}
                >
                  Success!
                </Typography>
                <Button
                  onClick={() => {
                    setLoading(0);
                    // closeForm();
                  }}
                  variant="outlined"
                  sx={{
                    background: COLORS.primary,
                    "&:hover": COLORS.hover,
                    textTransform: BUTTONSTYLE.textTransform,
                    borderRadius: BUTTONSTYLE.borderRadius,
                    fontWeight: BUTTONSTYLE.fontWeight,
                    color: "#fff",
                    fontFamily: "InterBold",
                    padding: "10px",
                    maxWidth: "100px",
                    width: "100%",
                    border: "#6823D1 dashed 2x",
                    marginTop: "10px",
                  }}
                >
                  Okay
                </Button>
              </>
            )}
            {loading === 3 && (
              <>
                <ErrorOutlineIcon sx={{ color: "#b62424", fontSize: "5em" }} />
                <Typography
                  variant="h5"
                  sx={{ color: "#b62424", fontFamily: "InterBold" }}
                >
                  Error!
                </Typography>
                <Button
                  onClick={() => {
                    setLoading(0);
                    // loadingModal(0);
                    // closeForm();
                  }}
                  variant="outlined"
                  sx={{
                    color: "#b62424",
                    fontFamily: "InterBold",
                    borderColor: "#b62424",
                    "&:hover": {
                      borderColor: "#b62424",
                    },
                  }}
                >
                  Try Again
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default LoaderModal;
