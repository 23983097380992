import React from "react";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import { COLORS, BUTTONSTYLE } from "../constants/config";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import { NavLink } from "react-router-dom";
import axios from "axios";
import LoaderModal from "../components/LoaderModal";

function Registration() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [role, setRole] = useState(0);
  const [loading, setLoading] = useState(0);

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(1);
    try {
      const data = {
        firstname: firstName,
        lastname: lastName,
        email: adminEmail,
        role: role,
      };
      const baseUrl =
        "https://dxxzla1v6k.execute-api.us-east-1.amazonaws.com/dev";
      const registration = "/create-admin-user";
      const response = await axios.post(`${baseUrl}${registration}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setLoading(2);
      console.log(response);
    } catch (error) {
      setLoading(3);
      console.log(error);
    }
  };

  return (
    <Box sx={mainContainer}>
      <Box sx={innerContainer}>
        <Typography variant="h6" sx={titleStyle}>
          Add user
        </Typography>
        <form onSubmit={onSubmit}>
          <InputLabel htmlFor="input-name">First Name</InputLabel>
          <TextField
            variant="outlined"
            sx={fieldStyle}
            value={firstName}
            type="text"
            onInput={(x) =>
              setFirstName(x.target.value.replace(/[^a-z]/gi, ""))
            }
            onPaste={(x) =>
              setFirstName(x.target.value.replace(/[^a-z]/gi, ""))
            }
            onChange={(x) =>
              setFirstName(x.target.value.replace(/[^a-z]/gi, ""))
            }
          />
          <InputLabel htmlFor="input-name">Last Name</InputLabel>
          <TextField
            variant="outlined"
            sx={fieldStyle}
            value={lastName}
            type="text"
            onInput={(x) => setLastName(x.target.value.replace(/[^a-z]/gi, ""))}
            onPaste={(x) => setLastName(x.target.value.replace(/[^a-z]/gi, ""))}
            onChange={(x) =>
              setLastName(x.target.value.replace(/[^a-z]/gi, ""))
            }
          />
          <InputLabel htmlFor="input-email">Email</InputLabel>
          <TextField
            // disabled
            sx={fieldStyle}
            value={adminEmail}
            type="email"
            onInput={(x) => setAdminEmail(x.target.value)}
            onPaste={(x) => setAdminEmail(x.target.value)}
            onChange={(x) => setAdminEmail(x.target.value)}
          />
          <FormControl>
            <FormLabel
              sx={formLabelStyle}
              id="demo-row-radio-buttons-group-label"
            >
              Access Level
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                sx={formControlStyle}
                value="admin"
                control={<Radio sx={radioStyle} />}
                label="Admin"
                onChange={() => setRole(1)}
              />
              <FormControlLabel
                sx={formControlStyle}
                value="editor"
                control={<Radio sx={radioStyle} />}
                label="Editor"
                onChange={() => setRole(2)}
              />
            </RadioGroup>
          </FormControl>
          <Box sx={buttonContainer}>
            <Button variant="outlined" sx={buttonStyle} type="submit">
              Invite
            </Button>
            <NavLink to="/">
              <Button variant="outlined" sx={buttonStyleAlt}>
                Cancel
              </Button>
            </NavLink>
            <LoaderModal loadingType={loading} />
          </Box>
        </form>
      </Box>
    </Box>
  );
}

const mainContainer = {
  top: "50%",
  left: "50%",
  transform: "translate(-50% , -50%)",
  position: "absolute",
  width: "100%",
};

const innerContainer = {
  display: "flex",
  flexDirection: "column",
  maxWidth: "500px",
  margin: "auto",
  gap: "10px",
};

const buttonContainer = {
  display: "flex",
  gap: "10px",
};

const titleStyle = {
  borderBottom: "2px #6823D1 solid",
  color: "#767676e6",
  fontFamily: "InterMedium",
};

const radioStyle = {
  color: "#76767680",
  "&.Mui-checked": {
    color: "#6823D1",
  },
};

const formControlStyle = {
  color: "#767676e6",
  fontFamily: "InterMedium",
};

const formLabelStyle = {
  color: "#767676e6 !important",
  fontFamily: "InterMedium",
};

const buttonStyle = {
  color: COLORS.text_2,
  background: COLORS.primary,
  border: "1px solid #767676e6",
  "&:hover": COLORS.hover,
  textTransform: BUTTONSTYLE.textTransform,
  borderRadius: BUTTONSTYLE.borderRadius,
  fontFamily: BUTTONSTYLE.fontFamily,
  fontWeight: BUTTONSTYLE.fontWeight,
  padding: BUTTONSTYLE.padding,
  borderColor: BUTTONSTYLE.borderColor,
};

const buttonStyleAlt = {
  color: COLORS.text_3,
  background: COLORS.background,
  border: "1px solid #767676e6",
  "&:hover": COLORS.hover_2,
  textTransform: BUTTONSTYLE.textTransform,
  borderRadius: BUTTONSTYLE.borderRadius,
  fontFamily: BUTTONSTYLE.fontFamily,
  fontWeight: BUTTONSTYLE.fontWeight,
  padding: BUTTONSTYLE.padding,
};

const fieldStyle = {
  "& label.Mui-focused": {
    color: COLORS.primary,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.primary,
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: COLORS.primary,
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: COLORS.primary,
    },
  },

  "& input:focus::placeholder": {
    color: "transparent",
  },
  width: "500px",
};

export default Registration;
