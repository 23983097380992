import { NavLink, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { COLORS, IMAGES, BUTTONSTYLE } from "../constants/config";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import ErrorMessage from "../components/ErrorMessage";
import { AccountContext } from "../context/Account";
import { Auth } from "aws-amplify";
import LoaderModal from "../components/LoaderModal";
import axios from "axios";

const Login = () => {
  let navigate = useNavigate();
  const { authenticate } = useContext(AccountContext);
  const [emailLogin, setEmailLogin] = useState("");
  const [emailPassword, setEmailPassword] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [displayPassword, setDisplayPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(0);
  // const [currentAdmin, setCurrentAdmin] = useState();

  const setLocalStorage = (attr, data) => {
    localStorage.setItem(attr, data);
  };

  // const getActiveAdminRole = async (id) => {
  //   const baseUrl =
  //     "https://dxxzla1v6k.execute-api.us-east-1.amazonaws.com/dev";
  //   try {
  //     const response = axios.get(`${baseUrl}/admin-user-by-id?id=${id}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     setCurrentAdmin(response.data);
  //     console.log(response.data, "adminrole");
  //   } catch (err) {
  //     console.log("err", err);
  //   }
  // };

  // useEffect(() => {
  //   console.log(currentAdmin);
  // }, []);

  const onSubmit = async (event) => {
    setLoading(1);
    event.preventDefault();
    // console.log(currentAdmin, "adminrole");
    // getActiveAdminRole("Role", currentAdmin.role);
    // setLocalStorage("Role", currentAdmin.role);
    authenticate(email, password)
      .then((x) => {
        console.log(x);
      })
      .catch((error) => {
        console.log("test", error);
      });
    setIsSubmit(true);

    // if (email && password) {
    //   setLoading(1);
    //   try {
    //     await Auth.signIn(email, password);
    //     setLocalStorage("loggedIn", true);
    //     setLoading(2);
    //     navigate("/");
    //     window.location.href = "/admin";
    //   } catch (error) {
    //     if (String(error).includes("Incorrect Username or password.")) {
    //       setErrorMessage("Invalid Email or Password");
    //     } else {
    //       // setErrorMessage("Invalid Email or Password");
    //       setLoading(1);
    //       setErrorMessage("");
    //     }
    //     console.log(error);
    //     setLoading(0);
    //   }
    // } else {
    //   setLoading(0);
    //   setErrorMessage("Fields cannot be empty");
    // }
    await authenticate(email, password)
      .then(() => {
        setLocalStorage("loggedIn", true);
        setLoading(0);
        navigate("/");
      })

      .catch((error) => {
        if (
          String(error).includes(
            "NotAuthorizedException: Incorrect username or password."
          )
        ) {
          setLoading(0);
          setErrorMessage("Invalid Email or Password");
        } else if (!email || !password) {
          setErrorMessage("Please enter a valid username and password.");
        } else if (
          String(error).includes("NotAuthorizedException: User is disabled")
        ) {
          setLoading(0);
          setErrorMessage(
            "Your account has been disabled. Please contact the administrator."
          );
        }
        setLoading(0);
        console.log("test", error);
      });
  };

  return (
    <Box style={containerStyle}>
      <Box sx={leftStyle} display={{ xs: "none", md: "block" }}>
        <Box style={{ position: "relative", height: "100%" }}>
          <Box
            style={{
              position: "absolute",
              top: "20%",
              zIndex: 1,
              padding: "0 50px",
              width: "auto",
            }}
          >
            <h1
              style={{
                fontFamily: "InterBlack",
                color: "#38205C",
                fontSize: "32px",
              }}
            >
              Content Management System
            </h1>
          </Box>
          <img
            style={{
              objectFit: "cover",
              width: "100%",
              height: "-webkit-fill-available",
              opacity: "50%",
            }}
            src={IMAGES.backgroundHalf}
            alt="map-half"
          />
        </Box>
      </Box>
      <Container style={rightStyle} className="rightContainer">
        <form onSubmit={onSubmit}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={3}
            justify="center"
            direction="row"
          >
            <Grid item xs={12}>
              <h1
                style={{
                  fontFamily: "InterBlack",
                  color: "#38205C",
                  fontSize: "32px",
                }}
              >
                Log into your account
              </h1>
            </Grid>
            <Grid item xs={12} sm={12}></Grid>
            {errorMessage && (
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <ErrorMessage message={errorMessage} />
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <TextField
                sx={inputStyle}
                id="email-input"
                name="email"
                label="Email"
                type="email"
                value={email}
                onPaste={(event) => setEmail(event.target.value)}
                onChange={(event) => setEmail(event.target.value)}
                onInput={(event) => setEmailLogin(event.target.value)}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                sx={inputStyle}
                id="password-input"
                name="password"
                label="Password"
                type={displayPassword ? "text" : "password"}
                value={password}
                onPaste={(event) => setPassword(event.target.value)}
                onChange={(event) => setPassword(event.target.value)}
                onInput={(event) => setEmailPassword(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setDisplayPassword((x) => !x)}
                        edge="end"
                      >
                        {displayPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ color: "#000", textAlign: "right" }}
            >
              <NavLink
                to="/reset-password"
                style={{ textDecoration: "underline" }}
              >
                Forgot your password?
              </NavLink>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                sx={{
                  background: COLORS.primary,
                  "&:hover": COLORS.hover,
                  textTransform: BUTTONSTYLE.textTransform,
                  borderRadius: BUTTONSTYLE.borderRadius_2,
                  fontFamily: BUTTONSTYLE.fontFamily,
                  fontWeight: BUTTONSTYLE.fontWeight,
                  padding: BUTTONSTYLE.padding_2,
                  maxWidth: BUTTONSTYLE.maxWidth,
                  width: "100%",
                }}
                type="submit"
                variant="contained"
                color="primary"
              >
                Continue
              </Button>
              <LoaderModal hasText={false} loadingType={loading} />
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  height: "max-content",
};

const leftStyle = {
  flex: "1 1 0",
  backgroundColor: "#C6EBDD",
};

const rightStyle = {
  flex: "1 1 0",
  padding: "120px",
};

const inputStyle = {
  width: "100%",
  margin: 0,
  "& label.Mui-focused": {
    color: COLORS.primary,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.primary,
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: COLORS.primary,
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: COLORS.primary,
    },
  },
};

export default Login;
