import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LoaderModal from "./LoaderModal";

function MaterialsList({
  materials,
  // setMaterials,
  // getMaterials,
  setCount,
  // filteredMaterials,
}) {
  const [loading, setLoading] = useState(0);
  const [materialsList, setMaterialsList] = useState(materials);
  // const [isActive, setIsActive] = useState(false);
  const baseUrl = "https://dxxzla1v6k.execute-api.us-east-1.amazonaws.com/dev";

  useEffect(() => {
    console.log(materials, "materials hit");
  }, [materials]);

  const reviewMaterial = async (id, status) => {
    // console.log("params test", { id, status });
    const a = materials.map((x) => {
      if (x.ID === id) {
        console.log(x, id, status);
        x.status = status;
      }
      return x;
    });

    console.log(a);
    // setMaterialsList(a);
    // getMaterials();
    try {
      setLoading(1);
      const result = await axios.put(
        `${baseUrl}/review-material?id=${id}`,
        { status },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setCount();
      // getMaterials();
      // setMaterialsList(a);
      console.log("materials update", result);
      return result.status === 200 && result && setLoading(0);
    } catch (err) {
      setLoading(3);
      console.log("err", err);
    }
  };

  // useEffect(() => {
  //   if (filteredMaterials) {
  //     if (filteredMaterials.length !== 0) {
  //       setMaterialsList(filteredMaterials);
  //     } else {
  //       setMaterialsList([]);
  //     }
  //   } else {
  //     setMaterialsList(materials);
  //   }
  //   console.log(filteredMaterials, "hello6");
  // }, [filteredMaterials]);

  const sortByDate = [...materials].sort((a, b) =>
    a.createdAt.substring(0, 10) > b.createdAt.substring(0, 10) ? -1 : 1
  );

  return (
    <Paper sx={{ marginTop: "50px", width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 540 }} component={Paper}>
        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={tableHeader}>Name</TableCell>
              <TableCell sx={tableHeader} align="left">
                Email
              </TableCell>
              <TableCell sx={tableHeader} align="left">
                Phone Number
              </TableCell>
              <TableCell sx={tableHeader} align="left">
                Date
              </TableCell>
              <TableCell sx={tableHeader} align="left">
                Description
              </TableCell>
              <TableCell sx={tableHeader} align="left">
                Category
              </TableCell>
              <TableCell sx={tableHeader} align="center">
                Type
              </TableCell>
              <TableCell sx={tableHeader} align="center">
                Price
              </TableCell>
              <TableCell sx={tableHeader} align="center">
                Status
              </TableCell>
              <TableCell sx={tableHeader} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {materials.length !== 0 &&
              materials &&
              // materials.map((material) => (
              sortByDate.map((material) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {material?.ownerName}
                  </TableCell>
                  <TableCell align="left">{material?.ownerEmail}</TableCell>
                  <TableCell align="left">{material?.phoneNumber}</TableCell>
                  <TableCell align="left">
                    {material?.createdAt.substring(0, 10)}
                  </TableCell>
                  <TableCell sx={{ maxWidth: "300px" }} align="left">
                    {material?.description}
                  </TableCell>
                  <TableCell align="left">{material?.category}</TableCell>
                  <TableCell align="center">{material?.tradeType}</TableCell>
                  <TableCell align="center">{material?.price}</TableCell>
                  <TableCell
                    sx={{ textTransform: "capitalize" }}
                    align="center"
                  >
                    {material?.status}
                  </TableCell>
                  <TableCell align="center" sx={{ display: "flex" }}>
                    <Button
                      sx={{
                        background:
                          material?.status === "active"
                            ? "rgb(90, 198, 156)"
                            : "",
                        color:
                          material?.status === "active"
                            ? "rgb(255, 255, 255)"
                            : "",
                      }}
                      variant="outlined"
                      onClick={() => reviewMaterial(material?.ID, "active")}
                    >
                      approve
                    </Button>
                    <Button
                      sx={{
                        background:
                          material?.status === "rejected" ? "#ef3f3f" : "",
                        color:
                          material?.status === "rejected"
                            ? "rgb(255, 255, 255)"
                            : "",
                      }}
                      variant="outlined"
                      onClick={() => reviewMaterial(material?.ID, "rejected")}
                    >
                      reject
                    </Button>
                  </TableCell>
                  <LoaderModal loadingType={loading} />
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const tableHeader = {
  fontFamily: "InterMedium",
  textTransform: "uppercase",
};

export default MaterialsList;
