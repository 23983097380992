import { useState } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";

const UpdateUserStatus = ({ user, updateStatus }) => {
  const [selected, setSelected] = useState();

  const parseData = (user, propertyName) => {
    return user.fields ? user.fields[propertyName] : user[propertyName];
  };

  return (
    <FormControl>
      <FormLabel sx={formLabelStyle} id="demo-row-radio-buttons-group-label">
        Status
      </FormLabel>
      <RadioGroup
        onChange={(e) => setSelected(e.target.value)}
        defaultValue={
          parseData(user, "status") == "enabled"
            ? true
            : parseData(user, "status") == "disabled"
            ? false
            : null
        }
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          sx={formControlStyle}
          name="test"
          value={true}
          control={<Radio sx={radioStyle} />}
          label="Active"
        />
        <FormControlLabel
          sx={formControlStyle}
          name="test"
          value={false}
          control={<Radio sx={radioStyle} />}
          label="Inactive"
        />

        <FormLabel sx={formLabelStyle} id="demo-row-radio-buttons-group-label">
          <Button variant="outlined" onClick={() => updateStatus(selected)}>
            Save
          </Button>
          <Button variant="outlined">Cancel</Button>
        </FormLabel>
      </RadioGroup>
    </FormControl>
  );
};

const radioStyle = {
  color: "#76767680",
  "&.Mui-checked": {
    color: "#6823D1",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
};

const formControlStyle = {
  paddingTop: "10px",
  color: "#000 !important",
  fontFamily: "InterMedium",
  "&.MuiFormControlLabel-root .MuiFormControlLabel-label": { fontSize: "13px" },
};

const formLabelStyle = {
  paddingTop: "10px",
  color: "#000 !important",
  fontFamily: "InterMedium",
  fontWeight: "600",
  fontSize: "13px",
};

export default UpdateUserStatus;
