import { COLORS } from "../constants/config";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import LoaderModal from "./LoaderModal";

function PageContents() {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(0);

  useEffect(() => {
    getAllContent();
  }, []);

  const setContentData = () => ({
    heroHeader: "",
    heroSubHeader: "",
    sellerHeader: "",
    sellerSubHeader: "",
    cardOneTitle: "",
    cardOneContent: "",
    cardTwoTitle: "",
    cardTwoContent: "",
    cardThreeTitle: "",
    cardThreeContent: "",
    aboutSectionHeader: "",
    aboutSectionParagraph: "",
    contactSectionHeader: "",
    footerParagraph: "",
    facebookLink: "",
    instagramLink: "",
    twitterLink: "",
    youtubeLink: "",
    aboutPageFirstHeader: "",
    aboutPageFirstParagraph: "",
    aboutPageSecondHeader: "",
    aboutPageSecondParagraph: "",
    aboutPageThirdHeader: "",
    aboutPageThirdParagraph: "",
  });

  const getAllContent = async (e) => {
    // axios
    //   .get("https://dxxzla1v6k.execute-api.us-east-1.amazonaws.com/dev/content")
    //   .then((response) => {
    //     console.log(response);
    //     const data = response.data;
    //     setContent(data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    try {
      const response = await axios.get(
        "https://dxxzla1v6k.execute-api.us-east-1.amazonaws.com/dev/content"
      );
      console.log(response);
      setContent(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e, status) => {
    try {
      setLoading(1);
      const response = await axios({
        url: "https://dxxzla1v6k.execute-api.us-east-1.amazonaws.com/dev/content",
        method: "PUT",
        data: content,
      });
      setLoading(2);
      // setContent(contentData);
      console.log(response, "this is working");
    } catch (error) {
      setLoading(3);
      console.log(error);
    }
  };

  return (
    <>
      <Box sx={scrollableDiv}>
        <h2>Home Page</h2>
        <Box sx={postsContainer}>
          <h3>Header</h3>
          <Box sx={postsSectionContainer}>
            <Box sx={postsFieldContainer}>
              <h3>Hero Header</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    heroHeader: e.target.value,
                  }))
                }
                onSubmit={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    heroHeader: e.target.value,
                  }))
                }
                value={content?.heroHeader}
                sx={inputStyle}
                aria-label="hero header"
                multiline
              />
            </Box>
            <Box sx={postsFieldContainer}>
              <h3>Hero Sub Header</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["heroSubHeader"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["heroSubHeader"]: e.target.value,
                  }))
                }
                value={content?.heroSubHeader}
                sx={inputStyle}
                aria-label="hero sub header"
                multiline
              />
            </Box>
          </Box>
        </Box>

        <Box sx={postsContainer}>
          <h3>Become A Seller</h3>
          <Box sx={postsSectionContainer}>
            <Box sx={postsFieldContainer}>
              <h3>Seller Header</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["sellerHeader"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["sellerHeader"]: e.target.value,
                  }))
                }
                value={content?.sellerHeader}
                sx={inputStyle}
                aria-label="seller header"
                multiline
              />
            </Box>
            <Box sx={postsFieldContainer}>
              <h3>Seller Sub Header</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["sellerSubHeader"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["sellerSubHeader"]: e.target.value,
                  }))
                }
                value={content?.sellerSubHeader}
                sx={inputStyle}
                aria-label="seller sub header"
                multiline
              />
            </Box>
            <Box sx={postsFieldContainer}>
              <h3>Card 1 Title</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["cardOneTitle"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["cardOneTitle"]: e.target.value,
                  }))
                }
                value={content?.cardOneTitle}
                sx={inputStyle}
                aria-label="card one title"
                multiline
              />
            </Box>
            <Box sx={postsFieldContainer}>
              <h3>Card 1 Content</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["cardOneContent"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["cardOneContent"]: e.target.value,
                  }))
                }
                value={content?.cardOneContent}
                size="large"
                sx={inputStyle}
                aria-label="card one content"
                multiline
                minRows="5"
              />
            </Box>
            <Box sx={postsFieldContainer}>
              <h3>Card 2 Title</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["cardTwoTitle"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["cardTwoTitle"]: e.target.value,
                  }))
                }
                value={content?.cardTwoTitle}
                sx={inputStyle}
                aria-label="card two title"
                multiline
              />
            </Box>
            <Box sx={postsFieldContainer}>
              <h3>Card 2 Content</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["cardTwoContent"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["cardTwoContent"]: e.target.value,
                  }))
                }
                value={content?.cardTwoContent}
                size="large"
                sx={inputStyle}
                aria-label="card two content"
                multiline
                minRows="5"
              />
            </Box>
            <Box sx={postsFieldContainer}>
              <h3>Card 3 Title</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["cardThreeTitle"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["cardThreeTitle"]: e.target.value,
                  }))
                }
                value={content?.cardThreeTitle}
                sx={inputStyle}
                aria-label="card three title"
                multiline
              />
            </Box>
            <Box sx={postsFieldContainer}>
              <h3>Card 3 Content</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["cardThreeContent"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["cardThreeContent"]: e.target.value,
                  }))
                }
                value={content?.cardThreeContent}
                size="large"
                sx={inputStyle}
                aria-label="card three content"
                multiline
                minRows="5"
              />
            </Box>
          </Box>
        </Box>
        <Box sx={postsContainer}>
          <h3>About Us</h3>
          <Box sx={postsSectionContainer}>
            <Box sx={postsFieldContainer}>
              <h3>About Section Header</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["aboutSectionHeader"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["aboutSectionHeader"]: e.target.value,
                  }))
                }
                value={content?.aboutSectionHeader}
                sx={inputStyle}
                aria-label="about section header"
                multiline
              />
            </Box>
            <Box sx={postsFieldContainer}>
              <h3>About Section Paragraph</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["aboutSectionParagraph"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["aboutSectionParagraph"]: e.target.value,
                  }))
                }
                value={content?.aboutSectionParagraph}
                size="large"
                sx={inputStyle}
                aria-label="about section paragraph"
                multiline
                minRows="5"
              />
            </Box>
          </Box>
        </Box>
        <Box sx={postsContainer}>
          <h3>Contact Us</h3>
          <Box sx={postsSectionContainer}>
            <Box sx={postsFieldContainer}>
              <h3>Contact Section Header</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["contactSectionHeader"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["contactSectionHeader"]: e.target.value,
                  }))
                }
                value={content?.contactSectionHeader}
                sx={inputStyle}
                aria-label="contact section header"
                multiline
              />
            </Box>
          </Box>
        </Box>
        <Box sx={postsContainer}>
          <h3>Footer</h3>
          <Box sx={postsSectionContainer}>
            <Box sx={postsFieldContainer}>
              <h3>Footer Paragraph</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["footerParagraph"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["footerParagraph"]: e.target.value,
                  }))
                }
                value={content?.footerParagraph}
                size="large"
                sx={inputStyle}
                aria-label="footer paragraph"
                multiline
                minRows="5"
              />
            </Box>
          </Box>
        </Box>
        <Box sx={postsContainer}>
          <h3>Footer Links</h3>
          <Box sx={postsSectionContainer}>
            <Box sx={postsFieldContainer}>
              <h3>Facebook Link</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["facebookLink"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["facebookLink"]: e.target.value,
                  }))
                }
                value={content?.facebookLink}
                sx={inputStyle}
                aria-label="facebook link"
                multiline
              />
            </Box>
            <Box sx={postsFieldContainer}>
              <h3>Instagram Link</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["instagramLink"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["instagramLink"]: e.target.value,
                  }))
                }
                value={content?.instagramLink}
                sx={inputStyle}
                aria-label="instagram link"
                multiline
              />
            </Box>
            <Box sx={postsFieldContainer}>
              <h3>Twitter Link</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["twitterLink"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["twitterLink"]: e.target.value,
                  }))
                }
                value={content?.twitterLink}
                sx={inputStyle}
                aria-label="twitter Link"
                multiline
              />
            </Box>
            <Box sx={postsFieldContainer}>
              <h3>Youtube Link</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["youtubeLink"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["youtubeLink"]: e.target.value,
                  }))
                }
                value={content?.youtubeLink}
                sx={inputStyle}
                aria-label="youtube link"
                multiline
              />
            </Box>
          </Box>
        </Box>
        <h2>About Us Page</h2>
        <Box sx={postsContainer}>
          <h3>First Section</h3>
          <Box sx={postsSectionContainer}>
            <Box sx={postsFieldContainer}>
              <h3>Header</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["aboutPageFirstHeader"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["aboutPageFirstHeader"]: e.target.value,
                  }))
                }
                value={content?.aboutPageFirstHeader}
                sx={inputStyle}
                aria-label="about first page header"
                multiline
              />
            </Box>
            <Box sx={postsFieldContainer}>
              <h3>Paragraph</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["aboutPageFirstParagraph"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["aboutPageFirstParagraph"]: e.target.value,
                  }))
                }
                value={content?.aboutPageFirstParagraph}
                size="large"
                sx={inputStyle}
                aria-label="about page second paragraph"
                multiline
                minRows="5"
              />
            </Box>
          </Box>
        </Box>
        <Box sx={postsContainer}>
          <h3>Second Section</h3>
          <Box sx={postsSectionContainer}>
            <Box sx={postsFieldContainer}>
              <h3>Header</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["aboutPageSecondHeader"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["aboutPageSecondHeader"]: e.target.value,
                  }))
                }
                value={content?.aboutPageSecondHeader}
                sx={inputStyle}
                aria-label="about page second header"
                multiline
              />
            </Box>
            <Box sx={postsFieldContainer}>
              <h3>Paragraph</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["aboutPageSecondParagraph"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["aboutPageSecondParagraph"]: e.target.value,
                  }))
                }
                value={content?.aboutPageSecondParagraph}
                size="large"
                sx={inputStyle}
                aria-label="about page second paragraph"
                multiline
                minRows="5"
              />
            </Box>
          </Box>
        </Box>
        <Box sx={postsContainer}>
          <h3>Third Section</h3>
          <Box sx={postsSectionContainer}>
            <Box sx={postsFieldContainer}>
              <h3>Header</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["aboutPageThirdHeader"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["aboutPageThirdHeader"]: e.target.value,
                  }))
                }
                value={content?.aboutPageThirdHeader}
                sx={inputStyle}
                aria-label="about page third header"
                multiline
              />
            </Box>
            <Box sx={postsFieldContainer}>
              <h3>Paragraph</h3>
              <TextField
                onChange={(e) =>
                  setContent((previousValue) => ({
                    ...previousValue,
                    ["aboutPageThirdParagraph"]: e.target.value,
                  }))
                }
                onPaste={(e) =>
                  setContentData((previousValue) => ({
                    ...previousValue,
                    ["aboutPageThirdParagraph"]: e.target.value,
                  }))
                }
                value={content?.aboutPageThirdParagraph}
                size="large"
                sx={inputStyle}
                aria-label="about page third paragraph"
                multiline
                minRows="5"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ textAlign: "right", paddingTop: "50px" }}>
        <Button
          onClick={(e) => handleSubmit(e)}
          sx={{
            width: "150px",
            height: "50px",
            backgroundColor: "#6823D1",
            color: "#fff",
            fontFamily: "InterMedium",
            "&:hover": { backgroundColor: "#6823D1" },
          }}
          variant="outlined"
        >
          Update
        </Button>
        <LoaderModal hasText={true} loadingType={loading} />
      </Box>
    </>
  );
}

const postsContainer = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: "50px 0",
  flexDirection: { xs: "column", sm: "row" },
  borderBottom: "1px dashed black",
};

const postsSectionContainer = {
  display: "flex",
  flexDirection: "column",
  width: "90%",
};

const postsFieldContainer = {
  display: "flex",
  justifyContent: "space-between",
  width: "80%",
};

const scrollableDiv = {
  maxHeight: "60vh",
  overflowY: "auto",
};

const inputStyle = {
  margin: 0,
  "& label.Mui-focused": {
    color: COLORS.primary,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.primary,
  },

  "& .MuiFilledInput-underline:after": {
    borderBottomColor: COLORS.primary,
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: COLORS.primary,
    },
  },
  maxWidth: "1200px",
  width: "80%",
};

export default PageContents;
