import { Outlet, Navigate } from "react-router-dom";
import useLocalStorage from "../utils/hooks/useLocalStorage";

const UnauthenticatedRoutes = () => {
  const [loggedIn] = useLocalStorage("loggedIn");

  const useAuth = () => {
    return loggedIn;
  };

  const isAuth = useAuth();
  return !isAuth ? <Outlet /> : <Navigate to="/" />;
};

export default UnauthenticatedRoutes;
