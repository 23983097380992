import { createContext, useEffect } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { useState } from "react";
import axios from "axios";
import AdminPool from "../AdminPool";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Pool } from "@mui/icons-material";

const AccountContext = createContext();

const Account = (props) => {
  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();

      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          } else {
            resolve(session);
          }
        });
      } else {
        reject();
      }
    });
  };

  let navigate = useNavigate();

  const defaultUserProfile = {
    firstname: "",
    Username: "",
    lastname: "",
    email: "",
  };

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("loggedIn") ? true : false
  );

  const [userId, setUserId] = useState(
    localStorage.getItem("Username") ? localStorage.getItem("Usename") : ""
  );
  const [isExternal, setIsExternal] = useState(false);
  const [userPhoto, setUserPhoto] = useState("");
  const [userItems, setUserItems] = useState();
  const [userSellingItems, setUserSellingItems] = useState();
  const [userRequestingItems, setUserRequestingItems] = useState();
  const [userLocation, setUserLocation] = useState({
    latitude: 28.5551901,
    longitude: -81.6280337,
    isDefault: true,
  });
  const [userZipcode, setUserZipcode] = useState("");

  const [userProfile, setUserProfile] = useState(defaultUserProfile);
  const [userChangePasswordAttr, setUserChangePasswordAttr] = useState();
  const [cognitoUser, setCognitoUser] = useState();

  const setLocalStorage = (attr, data) => {
    localStorage.setItem(attr, data);
  };

  useEffect(() => {
    const syncFetchLocation = async (zipcode) => {};

    if (userLocation.isDefault && userZipcode) {
      syncFetchLocation(userZipcode);
    }
  }, [userZipcode, userLocation]);

  const getCurrentUser = async (id) => {};

  useEffect(() => {
    getCurrentUser();
  }, []);

  // cognito sign in
  const authenticate = async (Username, Password, Email, id) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool: AdminPool });
      const authDetails = new AuthenticationDetails({
        Username,
        Password,
        Email,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log(data);
          const userId = data.accessToken.payload.sub;
          setIsLoggedIn(true);
          setLocalStorage("loggedIn", true);
          setLocalStorage("userId", userId);
          setLocalStorage("Username", Username);
          setUserId(userId);
          getUser(userId);
          resolve(data);
          navigate("/");
        },
        onFailure: (err) => {
          console.log(err);
          reject(err);
        },
        newPasswordRequired: (data) => {
          navigate("/reset-password");
          console.log("newPasswordRequired: ", data);
          setUserChangePasswordAttr(data);
          setCognitoUser(user);
          resolve(data);
        },
      });
    });
  };

  const changePassword = (newPassword) => {
    const userAttr = userChangePasswordAttr;
    delete userAttr.email_verified;
    delete userAttr.email;

    console.log(userAttr);

    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      userChangePasswordAttr,
      {
        onSuccess: (result) => {
          console.log(result);
          // login
          navigate("/login");
        },
      }
    );
  };

  const getUser = async (userId) => {
    try {
      const response = await axios.get(
        `https://dxxzla1v6k.execute-api.us-east-1.amazonaws.com/dev/Username=${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setUserProfile(response.data);
      console.log(response, "response");
    } catch (err) {
      console.log(err);
    }
  };

  const logout = async () => {
    const user = AdminPool.getCurrentUser();
    if (user) {
      if (user.username.startsWith("google")) {
        localStorage.removeItem("loggedIn");
        localStorage.removeItem("userId");
        setIsLoggedIn(false);
        await Auth.signOut();
      } else {
        user.signOut();
        localStorage.clear();
        setIsLoggedIn(false);
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn && userId) {
      getUser(userId);
    }
  }, []);

  const getUserItems = async () => {};

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const setUserCoords = ({ latitude, longitude }) => {
    setUserLocation({ latitude, longitude });
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const isMobileChat = width <= 350;

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getUserItems,
        // getUserSellingItems,
        // getUserRequestingItems,
        isExternal,
        isLoggedIn,
        isMobile,
        isMobileChat,
        setUserPhoto,
        setUserProfile,
        userId,
        userItems,
        userLocation,
        userPhoto,
        userProfile,
        userRequestingItems,
        userSellingItems,
        setUserCoords,
        logout,
        changePassword,
        getSession,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
export { Account, AccountContext };
