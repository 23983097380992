import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { COLORS, IMAGES, BUTTONSTYLE, REGEX } from "../constants/config";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import ErrorMessage from "../components/ErrorMessage";
import { AccountContext } from "../context/Account";
import LoaderModal from "../components/LoaderModal";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const ResetPassword = () => {
  let navigate = useNavigate();
  const { authenticate, changePassword } = useContext(AccountContext);
  const [newPassword, setNewPassword] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [displayPassword, setDisplayPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passMinLength, setPassMinLength] = useState("");
  const [passLowerCase, setPassLowerCase] = useState("");
  const [passOneNumber, setPassOneNumber] = useState("");
  const [passOneSymbol, setPassOneSymbol] = useState("");
  const [passUpperCase, setPassUpperCase] = useState("");
  const [loading, setLoading] = useState(0);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSubmit(true);
    if (
      newPassword &&
      passUpperCase &&
      passMinLength &&
      passOneNumber &&
      passLowerCase &&
      passOneSymbol
    ) {
      setLoading(1);
      console.log("Password changed successfully.");
      changePassword(newPassword);
      setLoading(2);
    } else {
      // setLoading(3);
      setErrorMessage("");
    }
  };

  const handleValidation = (userInput) => {
    const inputName = userInput.name;
    const inputValue = userInput.value;
    let errMsg = "";

    if (inputName === "password") {
      setNewPassword(inputValue);
      setPassLowerCase(REGEX.lowercase.test(inputValue));
      setPassUpperCase(REGEX.uppercase.test(inputValue));
      setPassOneNumber(REGEX.digits.test(inputValue));
      setPassOneSymbol(REGEX.specialChar.test(inputValue));
      setPassMinLength(REGEX.minLength.test(inputValue));
    }

    if (newPassword === 0) {
      errMsg = "Password is empty";
    } else if (!passUpperCase) {
      errMsg = "At least one Uppercase";
    } else if (!passLowerCase) {
      errMsg = "At least one Lowercase";
    } else if (!passOneNumber) {
      errMsg = "At least one digit";
    } else if (!passOneSymbol) {
      errMsg = "At least one Special Characters";
    } else if (!passMinLength) {
      errMsg = "At least minumum 11 characters";
    } else {
      errMsg = "";
    }
    setErrorMessage(errMsg);
  };

  return (
    <Box style={containerStyle}>
      <Box sx={leftStyle} display={{ xs: "none", md: "block" }}>
        <Box style={{ position: "relative", height: "100%" }}>
          <Box
            style={{
              position: "absolute",
              top: "20%",
              zIndex: 1,
              padding: "0 50px",
              width: "auto",
            }}
          >
            <h1
              style={{
                fontFamily: "InterBlack",
                color: "#38205C",
                fontSize: "32px",
              }}
            >
              Content Management System
            </h1>
          </Box>
          <img
            style={{
              objectFit: "cover",
              width: "100%",
              height: "-webkit-fill-available",
              opacity: "50%",
            }}
            src={IMAGES.backgroundHalf}
            alt="map-half"
          />
        </Box>
      </Box>
      <Container style={rightStyle} className="rightContainer">
        <form onSubmit={onSubmit}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={3}
            justify="center"
            direction="row"
          >
            <Grid item xs={12}>
              <h1
                style={{
                  fontFamily: "InterBlack",
                  color: "#38205C",
                  fontSize: "32px",
                }}
              >
                Reset Your Password
              </h1>
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <ErrorMessage message={errorMessage} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                sx={inputStyle}
                id="new-password-input"
                name="password"
                label="New Password"
                type={displayPassword ? "text" : "password"}
                value={newPassword}
                onPaste={(newValue) => handleValidation(newValue.target)}
                onChange={(newValue) => handleValidation(newValue.target)}
                onInput={(newValue) => handleValidation(newValue.target)}
                onBlur={(newValue) => handleValidation(newValue.target)}
                onKeyUp={(newValue) => handleValidation(newValue.target)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setDisplayPassword((x) => !x)}
                        edge="end"
                      >
                        {displayPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                sx={{
                  background: COLORS.primary,
                  "&:hover": COLORS.hover,
                  textTransform: BUTTONSTYLE.textTransform,
                  borderRadius: BUTTONSTYLE.borderRadius_2,
                  fontFamily: BUTTONSTYLE.fontFamily,
                  fontWeight: BUTTONSTYLE.fontWeight,
                  padding: BUTTONSTYLE.padding_2,
                  maxWidth: BUTTONSTYLE.maxWidth,
                  width: "100%",
                }}
                type="submit"
                variant="contained"
                color="primary"
                onClick={(newValue) => handleValidation(newValue.target)}
              >
                Continue
              </Button>
              <LoaderModal hasText={false} loadingType={loading} />
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  height: "max-content",
};

const leftStyle = {
  flex: "1 1 0",
  backgroundColor: "#C6EBDD",
};

const rightStyle = {
  flex: "1 1 0",
  padding: "120px",
};

const inputStyle = {
  width: "100%",
  margin: 0,
  "& label.Mui-focused": {
    color: COLORS.primary,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.primary,
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: COLORS.primary,
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: COLORS.primary,
    },
  },
};

export default ResetPassword;
