import "./App.css";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Registration from "./pages/Registration";
import ResetPassword from "./pages/ResetPassword";
import UnauthenticatedRoutes from "./routes/UnauthenticatedRoutes";
import ProtectedRoutes from "./routes/ProtectedRoutes";
// import Test from "./components/Test";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route exact path="/" element={<Admin />}>
            <Route exact path="registered-users" element={<Admin />} />
            <Route exact path="admin-users" element={<Admin />} />
            <Route exact path="materials" element={<Admin />} />
            <Route exact path="contents" element={<Admin />} />
          </Route>
          <Route exact path="registration" element={<Registration />} />
          {/* <Route exact path="test" element={<Test />} /> */}
        </Route>
        <Route element={<UnauthenticatedRoutes />}>
          <Route exact path="login" element={<Login />} />
          <Route exact path="reset-password" element={<ResetPassword />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
