import { NavLink } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState, useEffect } from "react";
import axios from "axios";
import UpdateRegisteredUserStatus from "./UpdateRegisteredUserStatus";
import LoaderModal from "./LoaderModal";

function RegisteredUsers({ registeredUsers, filteredUsers }) {
  const [isActiveId, setIsActiveId] = useState();
  const [users, setUsers] = useState(registeredUsers);
  const [loading, setLoading] = useState(0);

  useEffect(() => {
    setUsers(registeredUsers);
  }, [registeredUsers]);

  const updateStatus = async (val, userId, userid) => {
    if (val === undefined) {
      return;
    }

    users.map((x) => {
      const a = (x.fields ? x.fields.userid : x.userId) === userId;
    });
    const baseUrl =
      "https://dxxzla1v6k.execute-api.us-east-1.amazonaws.com/dev";
    setUsers((list) =>
      list.map((item) => {
        console.log(item.fields ? item.fields.userid : item.userId);
        if (item.fields) {
          return userId === item.fields.userid
            ? {
                fields: {
                  ...item.fields,
                  ["status"]: JSON.parse(val) ? "enabled" : "disabled",
                },
              }
            : item;
        } else {
          return item.userId === userId
            ? {
                ...item,
                ["status"]: JSON.parse(val) ? "enabled" : "disabled",
              }
            : item;
        }
        // return (item.fields ? item.fields.userid : item.userId) === userId
        //   ? {
        //       ...item,
        //       ["status"]: JSON.parse(val) ? "enabled" : "disabled",
        //     }
        //   : item;
      })
    );

    try {
      // update cognito user pool
      setLoading(1);
      const result = await axios.post(
        `${baseUrl}/update-user-status`,
        {
          id: userId,
          status: JSON.parse(val),
          // status: val,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // update dynamo db
      setLoading(0);
      return result.status === 200 && result;
    } catch (err) {
      setLoading(3);
      console.log("err", err);
    }
  };

  const openEditUsers = (userId) => {
    console.log(userId, "userId");
    setIsActiveId(isActiveId === userId ? "" : userId);
  };

  // console.log(registeredUsers, "test");

  // useEffect(() => {
  //   if (filteredUsers) {
  //     if (filteredUsers.length !== 0) {
  //       setUsers(filteredUsers);
  //     } else {
  //       setUsers([]);
  //     }
  //   } else {
  //     setUsers(registeredUsers);
  //   }
  //   console.log(filteredUsers, "hello5");
  // }, [filteredUsers]);

  const parseData = (user, propertyName) => {
    return user.fields ? user.fields[propertyName] : user[propertyName];
  };
  return (
    <Paper sx={{ marginTop: "50px", width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 540 }} component={Paper}>
        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={tableHeader}></TableCell>
              <TableCell sx={tableHeader}>Name</TableCell>
              <TableCell sx={tableHeader} align="center">
                Email
              </TableCell>
              <TableCell sx={tableHeader} align="center">
                Number
              </TableCell>
              <TableCell sx={tableHeader} align="center">
                Zip Code
              </TableCell>
              <TableCell sx={tableHeader} align="center">
                Birthday
              </TableCell>
              <TableCell sx={tableHeader} align="center">
                Status
              </TableCell>
              <TableCell sx={tableHeader} align="center">
                More
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.length !== 0 &&
              users &&
              users.map((user) => (
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                  <TableCell>
                    {parseData(user, "displayPhoto") && (
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "10px",
                        }}
                        alt="registered-users-photo"
                        src={`https://material-images.s3.amazonaws.com/${parseData(
                          user,
                          "displayPhoto"
                        )}`}
                      />
                    )}
                    {parseData(user, "displayphoto") && (
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "10px",
                        }}
                        alt="registered-users-photo"
                        src={`https://material-images.s3.amazonaws.com/${parseData(
                          user,
                          "displayphoto"
                        )}`}
                      />
                    )}
                    {!parseData(user, "displayphoto") &&
                      !parseData(user, "displayPhoto") && (
                        <AccountBoxIcon sx={{ fontSize: "100px" }} />
                      )}
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {parseData(user, "firstname")} {parseData(user, "lastname")}
                    {isActiveId === parseData(user, "id") && (
                      <Collapse
                        in={isActiveId}
                        timeout="auto"
                        unmountOnExit
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                          paddingTop: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            paddingTop: "10px",
                          }}
                        >
                          <Typography variant="p">
                            User Id: {parseData(user, "id")}
                          </Typography>
                          <Typography variant="p">
                            Registration Method:{" "}
                          </Typography>
                          <UpdateRegisteredUserStatus
                            user={user}
                            updateStatus={(val) =>
                              updateStatus(val, parseData(user, "id"))
                            }
                          />
                        </Box>
                      </Collapse>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {parseData(user, "email")}
                  </TableCell>
                  <TableCell align="center">
                    {parseData(user, "phoneNumber") ||
                      parseData(user, "phonenumber")}
                  </TableCell>
                  <TableCell align="center">
                    {parseData(user, "zipcode")}
                  </TableCell>
                  <TableCell align="center">
                    {parseData(user, "dateOfBirth") ||
                      parseData(user, "dateofbirth")}
                  </TableCell>
                  <TableCell
                    sx={{ textTransform: "capitalize" }}
                    align="center"
                  >
                    {parseData(user, "status")}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => openEditUsers(user.id)}>
                      {isActiveId === parseData(user, "id") ? (
                        <ArrowDropDownIcon />
                      ) : (
                        <ArrowRightIcon />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            <TableRow>
              <LoaderModal loadingType={loading} />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const tableHeader = {
  fontFamily: "InterMedium",
  textTransform: "uppercase",
};

export default RegisteredUsers;
