import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { FormControl, OutlinedInput } from "@mui/material";
import { COLORS } from "../constants/config";

const MaterialSearch = ({ setSearchTerm, search }) => {
  return (
    <FormControl
      sx={{ m: 1, display: "flex", flexDirection: "row", margin: "0" }}
      variant="standard"
    >
      <OutlinedInput
        placeholder="Search material posts"
        id="material-search"
        type="text"
        variant="outlined"
        sx={inputStyle}
        onChange={(e) => search(e.target.value)}
        endAdornment={
          <IconButton
            // onClick={() => searchSubmit()}
            type="submit"
            aria-label="material search"
          >
            <SearchIcon />
          </IconButton>
        }
      />
    </FormControl>
  );
};

const inputStyle = {
  margin: 0,
  "& label.Mui-focused": {
    color: COLORS.primary,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.primary,
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: COLORS.primary,
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: COLORS.primary,
    },
  },
  "& input:focus::placeholder": {
    color: "transparent",
  },
};

export default MaterialSearch;
