import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import IconButton from "@mui/material/IconButton";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Box } from "@mui/material";
import axios from "axios";
import UpdateUserStatus from "./UpdateUserStatus";
import UpdateUserRole from "./UpdateUserRole";
import LoaderModal from "./LoaderModal";

const AdminUsers = ({ registeredAdminUsers, filteredAdminUsers }) => {
  const [activeAdminId, setActiveAdminId] = useState();
  const [loading, setLoading] = useState(0);
  const openAdminSettings = (Username) => {
    setActiveAdminId(activeAdminId === Username ? "" : Username);
  };
  const [admins, setAdmins] = useState(registeredAdminUsers);

  const updateStatus = async (val, id) => {
    if (val === undefined) {
      return;
    }
    const baseUrl =
      "https://dxxzla1v6k.execute-api.us-east-1.amazonaws.com/dev";
    const endPoint = JSON.parse(val)
      ? "/enable-admin-user"
      : "/disable-admin-user";
    setAdmins((list) =>
      list.map((item) =>
        item.Username === id
          ? {
              ...item,
              ["Enabled"]: JSON.parse(val),
            }
          : item
      )
    );

    try {
      setLoading(1);
      const result = await axios.post(
        `${baseUrl}${endPoint}`,
        { id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(0);
      return result.status === 200 && result;
    } catch (err) {
      setLoading(3);
      console.log("err", err);
    }
  };

  const updateRole = async (val, id) => {
    console.log(val, id, "hello3");
    setAdmins((list) =>
      list.map((item) =>
        item.adminId === id
          ? {
              ...item,
              ["role"]: val,
            }
          : item
      )
    );
    const baseUrl =
      "https://dxxzla1v6k.execute-api.us-east-1.amazonaws.com/dev";
    try {
      setLoading(1);
      const result = await axios.put(
        `${baseUrl}/admin-user`,
        {
          id,
          role: val,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(0);
      return result.status === 200 && result;
    } catch (err) {
      setLoading(3);
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (filteredAdminUsers) {
      if (filteredAdminUsers.length !== 0) {
        setAdmins(filteredAdminUsers);
      } else {
        setAdmins([]);
      }
    } else {
      setAdmins(registeredAdminUsers);
    }
    console.log(filteredAdminUsers, "hello4");
  }, [filteredAdminUsers]);

  return (
    <Paper sx={{ marginTop: "50px", width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 540 }} component={Paper}>
        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={tableHeader}></TableCell>
              <TableCell sx={tableHeader}>Name</TableCell>
              <TableCell sx={tableHeader} align="center">
                Email
              </TableCell>
              <TableCell sx={tableHeader} align="center">
                Access Level
              </TableCell>
              <TableCell sx={tableHeader} align="center">
                Status
              </TableCell>
              <TableCell sx={tableHeader} align="center">
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {admins.length !== 0 &&
              admins &&
              admins.map((registeredAdminUser) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      width: "25px",
                      borderBottom: "1px dashed #76767680",
                    }}
                  >
                    <AccountBoxIcon sx={{ fontSize: "100px" }} />
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px dashed #76767680" }}
                    component="th"
                    scope="row"
                  >
                    {registeredAdminUser?.Attributes?.[0]?.Value}{" "}
                    {registeredAdminUser?.Attributes?.[1]?.Value}
                    {registeredAdminUser.Username === activeAdminId && (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <UpdateUserStatus
                          user={registeredAdminUser}
                          updateStatus={(val) =>
                            updateStatus(val, registeredAdminUser.Username)
                          }
                        />
                        {/* {registeredAdminUser.role === "1" && ( */}
                        <UpdateUserRole
                          registeredAdminUser={registeredAdminUser}
                          updateRole={(val) =>
                            updateRole(val, registeredAdminUser.Username)
                          }
                        />
                        {/* )} */}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px dashed #76767680" }}
                    align="center"
                  >
                    {registeredAdminUser?.Attributes?.[2]?.Value}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px dashed #76767680" }}
                    align="center"
                  >
                    {registeredAdminUser.role === "1" ? "Admin" : "Editor"}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px dashed #76767680" }}
                    align="center"
                  >
                    {registeredAdminUser.Enabled ? "Active" : "Inactive"}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px dashed #76767680" }}
                    align="center"
                  >
                    <IconButton
                      onClick={() =>
                        openAdminSettings(registeredAdminUser?.Username)
                      }
                    >
                      <BorderColorIcon />
                    </IconButton>
                  </TableCell>
                  <LoaderModal loadingType={loading} />
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const tableHeader = {
  fontFamily: "InterMedium",
  textTransform: "uppercase",
};

export default AdminUsers;
